import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../containers/SaasModern/Documentation';
import save_as_template_1 from '../../../../../common/src/assets/image/save_as_template_1.png';
import save_as_template_2 from '../../../../../common/src/assets/image/save_as_template_2.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal comes installed with a number of attractive templates.
        However, there may be cases where you'd like to customize a template to
        include things like business logos, contact information, photos,
        different colors, different layouts, etc. PrintableCal enables you to do
        this, and then re-use your customized template again and again.{' '}
        <Link to="/Documentation/How-To/Customize-a-Template">
          See the walkthrough
        </Link>{' '}
        to learn how to create a custom template.
      </p>
      <p>
        To save a custom template, click the <strong>Save as Template</strong>{' '}
        button on the PrintableCal tab of the Excel Ribbon. Note, this feature
        is only available in Excel, not Word or PowerPoint. Any custom templates
        you save will be listed in the{' '}
        <Link to="/Documentation/Create-Calendar/Choose-a-Template">
          Choose a Template
        </Link>{' '}
        step when creating calendars in both Word and Excel.
        <br />
        <Image alt="" src={save_as_template_1} style={{ width: 722 }} />
      </p>
      <p>
        After clicking the <strong>Save as Template</strong> button, the Save as
        Template task pane will appear. <br />
        <Image alt="" src={save_as_template_2} style={{ width: 442 }} />
      </p>
      <p>
        Before the template can be saved, a template name and type need to be
        specified. A description and thumbnail can be optionally entered and can
        help make it easier for you to{' '}
        <Link to="/Documentation/Create-Calendar/Choose-a-Template">
          find the template later
        </Link>
        , either by searching for the template or by seeing its thumbnail in the
        preview carousel.
      </p>
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Template name </strong>
            </td>
            <td>
              When saving a template, you can overwrite an existing template by
              selecting its name from the <strong>Template name</strong>{' '}
              drop-down list. You can also type in a new name to save as a new
              template.
              <br />
              <br />
              NOTE: If you customize one of the templates that was included with
              PrintableCal and you don't change the name, your custom template
              will override the default template. You can restore the default
              template by right-clicking the template's name in the template
              list and selecting the <strong>Restore to Original</strong> menu
              command.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Template type </strong>
            </td>
            <td>
              PrintableCal supports a number of template styles or types, such
              as Agenda and Month. These template types each have a specific
              purpose and require certain cell names to be present in the
              template in order to properly save. The template will be validated
              against the specified template type when the{' '}
              <strong>Save </strong>button is clicked. If any problems are
              detected, a validation window will appear providing information
              about the errors and how to fix them.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Description </strong>
            </td>
            <td>
              A description of the template can be entered. This description
              will be shown in the{' '}
              <Link to="/Documentation/Create-Calendar/Choose-a-Template">
                Choose a Template
              </Link>{' '}
              step when creating a calendar.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Thumbnail</strong>
            </td>
            <td>
              If you've previously generated a calendar using your custom
              template, you can take a screenshot of the previous output and
              save that as a thumbnail for the custom template. The specified
              thumbnail will be shown in the example carousel when selecting a
              template.
            </td>
          </tr>
          <tr>
            <td>
              <strong>
                Export to a file that can be imported on other computers{' '}
              </strong>
            </td>
            <td>
              When this box is checked, the template will also be saved to a
              file that can be imported on another computer by using the{' '}
              <Link to="/Documentation/Import-Template">Import Template</Link>{' '}
              button.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Save button </strong>
            </td>
            <td>
              This button will become enabled after a template name and type
              have been entered. Click this button to continue saving the
              template.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Save-As-Template"
      commentsId="commentsplus_post_151_489"
      title="Save Custom Templates"
      description="PrintableCal includes many printable calendar templates. These templates can be customized to include things like business logos, contact information, photos, and more."
      keywords="calendar templates, customizable calendar, printable calendar, printable template, Excel calendar, Word calendar, customize calendar, save template, export template"
      content={content}
    />
  );
};

export default Documentation;
